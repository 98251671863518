<template>
    <router-view/>

</template>

<script>

export default {
  name: 'App'  
}
</script>
<style>
@import url("https://ux.bluetooth.com/css/bluetooth.min.css?v=1");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap");
</style>