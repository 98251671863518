const appConfig = {    
    urlForDotCom: '',
    logoutUrls: {
      SpecWorkspace: '',
      LaunchStudio: '',
      LaunchStudioAdmin: '',
      DotCom: '',
      Apps: '',
      Voting: '',
      Zendesk: '',
      MSCpy: ''
    },
    logglyTags: window.location.host.split('.')[1],
    logglyToken: '1c7834ca-3f1e-48fa-9b21-e5f5a14af1da',
    logglySubDomain: 'bluetoothsig.com',
    urlForAppSettings: '',
    urlForWelcomeHub: '',
    client_id: '',
    redirect_uri: '',
    audience: '',
    auth0:
    {
      "domain": '',
      "client_id": '',
      "redirect_uri": '',
      "audience": ''
    }
  }
  
  switch (window.location.host) {        
    case 'localhost:8080':
      appConfig.urlForDotCom = 'https://www.bttest.net'
      appConfig.logoutUrls = {
        SpecWorkspace: 'https://sws.bttest.net/_layouts/15/SpecWorkflow.Core/signout.aspx',
        LaunchStudio: 'https://launchstudio.bttest.net/Account/Logout',
        LaunchStudioAdmin: 'https://launchstudioadmin.bttest.net/Account/Logout',
        DotCom: 'https://www.bttest.net/api/silentlogin/logout',
        Apps: 'https://apps.bttest.net/login/logout',
        Voting: 'https://voting.bttest.net/logout',
        Zendesk: 'https://support.bluetooth.com/access/logout?return_to=https%3A%2F%2Fsupport.bluetooth.com%2Fhc%2Fen-us',
        MSCpy: 'https://msc-generator.bttest.net/Account/Logout'
      }
      appConfig.urlForAppSettings = 'https://apps.bttest.net/mysettings#/',
      appConfig.urlForWelcomeHub = 'https://welcome.bttest.net'
      appConfig.auth0 =
      {
        "domain": "btpoc.us.auth0.com",
        "client_id": "dZgG7ndj038zrhb7aJwVhYvETB0dJjA0",
        "redirect_uri": "http://localhost:8080/callback",
        "audience": "https://btpoc.us.auth0.com/api/v2/"
      }
      break         
    case 'login.bttest.net': 
      appConfig.urlForDotCom = 'https://www.bttest.net'
      appConfig.logoutUrls = {
        SpecWorkspace: 'https://sws.bttest.net/_layouts/15/SpecWorkflow.Core/signout.aspx',
        LaunchStudio: 'https://launchstudio.bttest.net/Account/Logout',
        LaunchStudioAdmin: 'https://launchstudioadmin.bttest.net/Account/Logout',
        DotCom: 'https://www.bttest.net/api/silentlogin/logout',
        Apps: 'https://apps.bttest.net/login/logout',
        Voting: 'https://voting.bttest.net/logout',
        Zendesk: 'https://support.bluetooth.com/access/logout?return_to=https%3A%2F%2Fsupport.bluetooth.com%2Fhc%2Fen-us',
        MSCpy: 'https://msc-generator.bttest.net/Account/Logout'
      }
      appConfig.urlForAppSettings = 'https://apps.bttest.net/mysettings#/'
      appConfig.urlForWelcomeHub = 'https://welcome.bttest.net'
      appConfig.auth0 =
      {
        "domain": 'auth.bttest.net',
        "client_id": 'ZCxOO2AtcaQq7Y37lNPcmv4pRTzrEyH5',
        "redirect_uri": 'https://login.bttest.net/callback',
        "audience": 'https://bttestnet.auth0.com/api/v2/'
      }      
      break    
    case 'login.btstage.net':
      appConfig.urlForDotCom = 'https://www.btstage.net'
      appConfig.logoutUrls = {
        SpecWorkspace: 'https://sws.btstage.net/_layouts/15/SpecWorkflow.Core/signout.aspx',
        LaunchStudio: 'https://launchstudio.btstage.net/Account/Logout',
        LaunchStudioAdmin: 'https://launchstudioadmin.btstage.net/Account/Logout',
        DotCom: 'https://www.btstage.net/api/silentlogin/logout',
        Apps: 'https://apps.btstage.net/login/logout',
        Voting: 'https://voting.btstage.net/logout',
        Zendesk: 'https://support.bluetooth.com/access/logout?return_to=https%3A%2F%2Fsupport.bluetooth.com%2Fhc%2Fen-us',
        MSCpy: 'https://msc-generator.btstage.net/Account/Logout'
      }
      appConfig.urlForAppSettings = 'https://apps.btstage.net/mysettings#/',
      appConfig.urlForWelcomeHub = 'https://welcome.btstage.net',
      appConfig.auth0 =
      {
        "domain": 'auth.btstage.net',
        "client_id": 'Ms4LyCqaJEJD1B4bQbCIei8d4ASe3Lkr',
        "redirect_uri": 'https://login.btstage.net/callback',
        "audience": 'https://btstagenet.auth0.com/api/v2/'
      }
      break    
    case 'login.btharald.net':      
      appConfig.urlForDotCom = 'https://www.btharald.net'
      appConfig.logoutUrls = {
        SpecWorkspace: 'https://sws.btharald.net/_layouts/15/SpecWorkflow.Core/signout.aspx',
        LaunchStudio: 'https://launchstudio.btharald.net/Account/Logout',
        LaunchStudioAdmin: 'https://launchstudioadmin.btharald.net/Account/Logout',
        DotCom: 'https://www.btharald.net/api/silentlogin/logout',
        Apps: 'https://apps.btharald.net/login/logout',
        Voting: 'https://voting.btharald.net/logout',
        Zendesk: 'https://support.bluetooth.com/access/logout?return_to=https%3A%2F%2Fsupport.bluetooth.com%2Fhc%2Fen-us',
        MSCpy: 'https://msc-generator.btharald.net/Account/Logout'
      }
      appConfig.urlForAppSettings = 'https://apps.btharald.net/mysettings#/',
      appConfig.urlForWelcomeHub = 'https://welcome.btharald.net',
      appConfig.auth0 =
      {
        "domain": 'auth.btharald.net',
        "client_id": 'xR7zuXRa6m3wEoG3Lbt6lc69mUIlyqwR',
        "redirect_uri": 'https://login.btharald.net/callback',
        "audience": 'https://btharaldnet.auth0.com/api/v2/'
      }
      break    
    case 'login.bluetooth.com':      
      appConfig.urlForDotCom = 'https://www.bluetooth.com'
      appConfig.logglyTags = 'btprod ' + 'login'
      appConfig.logoutUrls = {
        SpecWorkspace: 'https://specworkspace.bluetooth.com/_layouts/15/SpecWorkflow.Core/signout.aspx',
        LaunchStudio: 'https://launchstudio.bluetooth.com/Account/Logout',
        LaunchStudioAdmin: 'https://launchstudioadmin.bluetooth.com/Account/Logout',
        DotCom: 'https://www.bluetooth.com/api/silentlogin/logout',
        Apps: 'https://apps.bluetooth.com/login/logout',
        Voting: 'https://voting.bluetooth.com/logout',
        Zendesk: 'https://support.bluetooth.com/access/logout?return_to=https%3A%2F%2Fsupport.bluetooth.com%2Fhc%2Fen-us',
        MSCpy: 'https://msc-generator.bluetooth.com/Account/Logout'
      }
      appConfig.urlForAppSettings = 'https://apps.bluetooth.com/mysettings#/',
      appConfig.urlForWelcomeHub = 'https://welcome.bluetooth.com',
      appConfig.auth0 =
      {
        "domain": 'authaz.bluetooth.com',
        "client_id": 'H0bSrzZeybn5HodDAXdeG1RTWw58K4Hp',
        "redirect_uri": 'https://login.bluetooth.com/callback',
        "audience": 'https://btcom.auth0.com/api/v2/'
      }     
      break
    default:
      break
  }
  
  export default appConfig
  