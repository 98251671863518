<template>
  <div v-if="!$auth.loading.value">    
  </div>
</template>

<script>

export default {
  name: 'Login'  
}
</script>