import createAuth0Client from '@auth0/auth0-spa-js'
import { computed, reactive, watchEffect } from 'vue'
import appConfig from '../appConfig.ts'

let client
const state = reactive({
  loading: true,
  isAuthenticated: false,
  user: {},
  popupOpen: false,
  error: null,
})

async function loginWithPopup() {
  state.popupOpen = true

  try {
    await client.loginWithPopup(0)
  } catch (e) {
    console.error(e)
  } finally {
    state.popupOpen = false
  }

  state.user = await client.getUser()
  state.isAuthenticated = true
}

async function handleRedirectCallback() {
  state.loading = true

  try {
    await client.handleRedirectCallback()
    state.user = await client.getUser()
    state.isAuthenticated = true
  } catch (e) {
    state.error = e
  } finally {
    state.loading = false
  }
}

function loginWithRedirect(o) {
  return client.loginWithRedirect(o)
}

function getIdTokenClaims(o) {
  return client.getIdTokenClaims(o)
}

function getTokenSilently(o) {
  return client.getTokenSilently(o)
}

function getTokenWithPopup(o) {
  return client.getTokenWithPopup(o)
}

function logout(o) {
  return client.logout(o)
}

const authPlugin = {
  isAuthenticated: computed(() => state.isAuthenticated),
  loading: computed(() => state.loading),
  user: computed(() => state.user),
  getIdTokenClaims,
  getTokenSilently,
  getTokenWithPopup,
  handleRedirectCallback,
  loginWithRedirect,
  loginWithPopup,
  logout,
}

const lowercaseKeys = obj =>
  Object.keys(obj).reduce((acc, key) => {
    acc[key.toLowerCase()] = obj[key];
    return acc;
  }, {});

export const routeGuard = (to, from, next ) => {
  const { isAuthenticated, loading, loginWithRedirect } = authPlugin

  const verify = () => {

    const queryReturnUrl = lowercaseKeys(to.query).returnurl;

    // If the user is authenticated go to appSettings
    if (isAuthenticated.value) {
      if (queryReturnUrl === undefined || queryReturnUrl === null)
      {
        window.location.href = appConfig.urlForWelcomeHub;      
      }
      else
      {
        if (queryReturnUrl.indexOf("~/media") >= 0 && queryReturnUrl.indexOf(appConfig.urlForDotCom) >= 0)
        {
          // If it's a file download page, go to .com home page with the file download page as a query string variable.
          window.location = (appConfig.urlForDotCom + '?filedownloadurl=' + queryReturnUrl);
        }
        
        window.location.href = queryReturnUrl;
      }
      
      return next()
    }

    if(queryReturnUrl !== undefined  && queryReturnUrl !== null){
      localStorage.setItem("returnurl", queryReturnUrl)
    }

    // Otherwise, log in
    loginWithRedirect({ appState: { targetUrl: to.fullPath } })
  }

  if(from.name == 'Callback')
  {   
    let localStorageReturnUrl = localStorage.getItem("returnurl");

    if(localStorageReturnUrl !== undefined && localStorageReturnUrl !== null)
    {
      if (localStorageReturnUrl.indexOf("~/media") >= 0 && localStorageReturnUrl.indexOf(appConfig.urlForDotCom) >= 0)
      {      
        // If it's a file download page, go to .com home page with the file download page as a query string variable.
        window.location = (appConfig.urlForDotCom + '?filedownloadurl=' + localStorageReturnUrl);
      }
      else
      {      
        window.location.href = localStorageReturnUrl;
      }

      localStorage.removeItem("returnurl");
    }
    else
    {      
      window.location.href = appConfig.urlForWelcomeHub //if returnUrl is not present, go to WelcomeHub
    }
  }

  // If loading has already finished, check our auth state using `fn()`
  if (!loading.value) {
    return verify()
  }

  // Watch for the loading property to change before we check isAuthenticated
  watchEffect(() => {
    if (loading.value === false) {
      return verify()
    }
  })
}

export const setupAuth = async (options, callbackRedirect) => {
  client = await createAuth0Client({
    ...options,
  })

  try {
    // If the user is returning to the app after authentication
    

    if (
      window.location.search.includes('code=') &&
      window.location.search.includes('state=')
    ) {
      // handle the redirect and retrieve tokens
      const { appState } = await client.handleRedirectCallback()

      // Notify subscribers that the redirect callback has happened, passing the appState
      // (useful for retrieving any pre-authentication state)
      callbackRedirect(appState)
    }
  } catch (e) {
    state.error = e
  } finally {
    // Initialize our internal authentication state
    state.isAuthenticated = await client.isAuthenticated()
    state.user = await client.getUser()
    state.loading = false
  }

  return {
    install: (app) => {
      app.config.globalProperties.$auth = authPlugin
    },
  }
}