<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div
          class="min-vh-100 d-flex flex-column justify-content-center align-items-center"
        >
          <img src="../assets/loader.svg" />
          <h3>Logging Out</h3>
          <iframe
            v-for="client in auth0Clients"
            :key="client"
            :name="client.name"
            :id="client.name"
            :src="client.logout_url"
            height="0"
            width="0"
            frameborder="0"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appConfig from "../appConfig.ts";

export default {
  name: "Logout",
  data() {
    return {
      auth0Clients: [
        {
          name: "BluetoothDotCom",
          logout_url: appConfig.logoutUrls.DotCom
        },
        {
          name: "BluetoothSpecWorkspace",
          logout_url: appConfig.logoutUrls.SpecWorkspace
        },
        {
          name: "BluetoothLaunchStudioAdmin",
          logout_url: appConfig.logoutUrls.LaunchStudioAdmin
        },
        {
          name: "BluetoothLaunchStudio",
          logout_url: appConfig.logoutUrls.LaunchStudio
        },
        {
          name: "BluetoothApps",
          logout_url: appConfig.logoutUrls.Apps
        },
        {
          name: "Voting",
          logout_url: appConfig.logoutUrls.Voting
        },
        {
          name: "Zendesk",
          logout_url: appConfig.logoutUrls.Zendesk
        },
        {
          name: "MSCpy",
          logout_url: appConfig.logoutUrls.MSCpy
        }
      ]
    };
  },
  mounted: function() {
    try {
      setTimeout(() => { 
        this.$auth.logout({
          returnTo: appConfig.urlForDotCom
        })
      }, 5000);
    } catch (err) {
      console.error(err);
      window.location.replace(
        window.location.origin +
          `/error?error=${encodeURI(
            "Error while trying to logout"
          )}&error_description=${encodeURI(err)}`
      );
    }
  }
};
</script>
